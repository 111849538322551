import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import Zoom from "@mui/material/Zoom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const LoadingDialog = ({ open, message }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="loading-dialog-description"
    >
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "24px",
        }}
      >
        <CircularProgress />
        <span style={{ marginTop: "16px" }}>{message}</span>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
