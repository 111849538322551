import React, { useContext } from "react";
import { Button, ButtonGroup, Grid, Typography, Divider } from "@mui/material";
import { OurContext } from "../context/context";
import CartItem from "./cartItem";
import { useState } from "react";

const Cart = ({ disabled }) => {
  const [totalPrice, setTotalPrice] = useState("0");
  const [totalWeight, setTotalWeight] = useState("0");
  const [totalItems, setTotalItems] = useState({});
  const [totalWiderField, setTotalWiderField] = useState(false);
  const ourContext = useContext(OurContext);

  let cartItemQuantity = ourContext.bugGenerator.wrapWithBugs(
    "Cart.cartItemQuantity",
    (itemName, quantity) => {
      setTotalItems((prevState) => ({ ...prevState, [itemName]: quantity }));
      return quantity;
    }
  );

  function resetAllItemQuantities() {
    ourContext.itemsData.forEach((item) => {
      item.state[1](0);
    });
    setTotalPrice(0);
    setTotalWeight(0);
  }

  function caclulateSum() {
    var sumPrices = 0;
    var sumWeights = 0;
    ourContext.itemsData.forEach((item) => {
      let itemState = item.state[0];
      let itemName = item.name;
      let itemPrice = item.price;
      let itemWeight = item.weight;
      let numItems = cartItemQuantity(
        itemName,
        parseIntOrDefault(itemState, 0)
      );

      sumPrices += numItems * itemPrice;
      sumWeights += numItems * itemWeight;
    });
    setTotalPrice(sumPrices);
    setTotalWeight(sumWeights);
    if (Number(sumPrices) > 999 || Number(sumWeights > 999)) {
      setTotalWiderField(true);
    } else {
      setTotalWiderField(false);
    }
  }

  function onCalculateButtonClicked() {
    caclulateSum();
  }

  function onRemoveAllButtonClicked() {
    resetAllItemQuantities();
  }

  function parseIntOrDefault(number, deFault) {
    return parseInt(number) || deFault;
  }

  function totalNumItems(itemsObject) {
    let sum = 0;
    Object.keys(itemsObject).map((obj) => {
      sum += itemsObject[obj];
    });
    return sum;
  }

  // You can declare another arrow function if you want:
  return (
    <div veelooka="Cart">
      <h2 style={{ marginBottom: "30px" }}>Cart</h2>
      {ourContext.itemsData.map((item, key) => (
        <CartItem
          disabled={disabled}
          CartData={item}
          key={key}
          BugGenerator={ourContext.bugGenerator}
        />
      ))}
      <Divider style={{ marginBottom: "20px", marginTop: "10px" }} />
      <Grid container alignItems="center">
        <Grid item xs={12} sx={{ display: "flex" }}>
          <Grid item xs={7} sx={{ display: "flex" }}>
            <Typography variant="h6">Total Price</Typography>
          </Grid>

          <Typography
            style={{
              background: "#eef4f7",
              border: "1px solid #42a4dc",
              borderRadius: "5px",
              width: "70px",
            }}
            align="center"
          >
            {totalPrice} $
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ display: "flex", marginTop: "5px" }}>
        <Grid item xs={7} sx={{ display: "flex" }}>
          <Typography variant="h6">Total Weight</Typography>
        </Grid>
        <Typography
          style={{
            background: "#eef4f7",
            border: "1px solid #42a4dc",
            borderRadius: "5px",
            width: "70px",
          }}
          align="center"
        >
          {totalWeight} Kg
        </Typography>
      </Grid>

      <Grid container justifyContent="right" style={{ marginTop: "20px" }}>
        <ButtonGroup orientation="vertical">
          <Grid>
            <Button
              disabled={disabled}
              veelooka="calculate"
              variant="contained"
              color="primary"
              onClick={onCalculateButtonClicked}
              style={{ background: "#42a4dc" }}
              fullWidth
            >
              Calculate
            </Button>
          </Grid>
          <Grid>
            <Button
              disabled={disabled}
              variant="contained"
              veelooka="removeAll"
              style={{ background: "#f07a9e", marginTop: "10px" }}
              onClick={onRemoveAllButtonClicked}
              fullWidth
            >
              Remove all
            </Button>
          </Grid>
        </ButtonGroup>
      </Grid>
    </div>
  );
};

export default Cart;
