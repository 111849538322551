export const BUG_CATEGORIES = [
  "Bug Category",
  [
    "Calculation error",
    "Out of bound (range)",
    "Responsiveness",
    "UI",
    "Functionality",
    "Timing",
  ],
];
export const PRIORITIES = ["Priority", ["Blocker", "High", "Medium", "Low"]];
