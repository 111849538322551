export function MessageQueue(address) {
  var reconnectTimeout;
  var that = this;
  var isConnected = false; // Set initial state to disconnected
  var reconnectionTimer;
  var isReconnecting = false; // Flag to track if reconnection is in progress

  this.connect = function () {
    try {
      if (this.webSocket) {
        this.webSocket.close();
      }
    } catch (e) {
      console.error(e);
    }

    this.webSocket = new WebSocket(address);
    this.webSocket.onerror = scheduleReconnection;
    this.webSocket.onopen = () => {
      isConnected = true;
      clearTimeout(reconnectionTimer); // Clear any existing reconnectionTimer
      isReconnecting = false; // Reset the reconnecting flag
      that.send("");
    };
    this.webSocket.onclose = () => {
      isConnected = false;
      scheduleReconnection();
    };
  };

  function scheduleReconnection() {
    if (isReconnecting) {
      // If reconnection is already in progress, skip this attempt
      return;
    }
    isReconnecting = true;
    // Set the reconnecting flag
    clearTimeout(reconnectTimeout);
    reconnectTimeout = setTimeout(() => {
      that.connect();
      isReconnecting = false;
      // Reset the reconnecting flag after the reconnection attempt
    }, 5000); // Delay of 5 seconds before reconnecting

    // Clear any existing reconnectionTimer
    clearTimeout(reconnectionTimer);

    // Listen for the online event (network connection regained)
    window.addEventListener("online", () => {
      that.connect();
      isReconnecting = false; // Reset the reconnecting flag
    });
  }

  // Add an event listener for the offline event (network connection lost)
  window.addEventListener("offline", scheduleReconnection);

  // Periodic network status check
  setInterval(function () {
    const isNetworkAvailable = navigator.onLine; // Check network status
    if (isNetworkAvailable && !isConnected) {
      // Internet connection is regained, attempt reconnection immediately
      that.connect();
      isReconnecting = false; // Reset the reconnecting flag
    } else if (!isNetworkAvailable && isConnected) {
      isConnected = false;
      scheduleReconnection();
    } else if (isConnected && that.webSocket.readyState !== 1) {
      isConnected = false;
      scheduleReconnection();
    } else if (that.webSocket.readyState === 1) {
      that.send("");
    }
  }, 5000);

  this.send = function (message) {
    try {
      if (this.webSocket.readyState === WebSocket.OPEN) {
        this.webSocket.send(message);
        document.reportConnectionState(true);
      } else if (this.webSocket.readyState === WebSocket.CLOSED) {
        scheduleReconnection();
      }
    } catch (e) {
      console.error(e);
      document.reportConnectionState(false);
      this.webSocket.close();
      scheduleReconnection();
    }
  };

  this.connect(); // Start the connection immediately
}
