import Typography from "@mui/material/Typography";

const bulletPoints = [
  {
    text: "To report a bug",
    subText: (
      <>
        <Typography component="div">
          1. Click on the "<strong>RECORD A BUG</strong>" button.
        </Typography>
        <Typography component="div">
          2. Reproduce the bug you identified with minimal steps as possible.
        </Typography>
        <Typography component="div">
          3. To complete the recording click on the "
          <strong>END RECORDING</strong>" button.
        </Typography>
        <Typography component="div">
          4. Select the relevant category of the bug.
        </Typography>
        <Typography component="div">
          5. Select the priority of the bug.
        </Typography>
        <Typography component="div">
          6. Provide a clear and short description of the bug.
        </Typography>
        <Typography component="div">
          7. To cancel a bug after recording it click on the "
          <strong>Cancel Bug</strong>" button.
        </Typography>
        <Typography component="div">
          8. Save all of the above by clicking on the "<strong>Save</strong>"
          button.
        </Typography>
      </>
    ),
  },
  {
    text: "Additional system options",
    subText: (
      <>
        <Typography component="div">
          • Click the "<strong>VIEW REPORTED BUGS</strong>" button to view the
          bugs you reported on.
        </Typography>
        <Typography component="div">
          • You can reset the app to its initial state by clicking the "
          <strong>RESET APP STATE</strong>", This will clear all of the data
          from the app and restore it to the initial state. It can help when
          investigating a problem. (Reported bugs will be saved).
        </Typography>
        <Typography component="div">
          • If you decide to complete the test before the designated time, you
          can submit it by clicking on the "
          <strong>FINISH & SUBMIT TEST</strong>" button. Keep in mind that you
          won't be able to return to the app and make any changes once
          submitting the test.
        </Typography>
      </>
    ),
  },
  {
    text: "General instructions",
    subText: (
      <>
        <Typography component="div">
          • Do not open the link in more than one location (browser/window)
        </Typography>
        <Typography component="div">
          • Do not refresh the browser during the test.
        </Typography>
        <Typography component="div">
          • You will have 1 hour to complete the test
        </Typography>
        <Typography component="div">
          • Important: <strong>Open the link only from a computer</strong> (not
          a cellphone).
        </Typography>
      </>
    ),
  },
];

export default bulletPoints;
