import { MessageQueue } from "./messageQueue";
import { isLocal } from "./utils";

function getCurrentUTCMilliseconds() {
  return Date.now();
}

function collectState() {
  //const ourContext = useContext(OurContext);
  //return ourContext;
  let ret = {};
  let elementsWithState = document.querySelectorAll("[veelookastate]");
  elementsWithState.forEach((element) => {
    let elementIdentifier = getElementVeelookaIdentifiersAsString(element);
    try {
      let stateValue = evaluateElementState(element);
      ret[elementIdentifier] = stateValue;
    } catch (ex) {
      //do not fail, just continue
      console.error(ex);
    }
  });
  return ret;
}

function evaluateElementState(element) {
  let stateType = element.getAttribute("veelookastate");
  let stateTokens = stateType.split(".");
  let toReturn = element;
  try {
    stateTokens.forEach((token) => {
      toReturn = toReturn[token] || element.getAttribute(token) || token;
    });
  } catch (ex) {
    console.error(ex);
    toReturn = undefined;
  }
  return toReturn;
}

var gMessageQueue;

export function reportEvent(eventType, elementIdentifier, state) {
  let event_json = JSON.stringify({
    event: eventType,
    timestamp: getCurrentUTCMilliseconds(),
    elementIdentifier: elementIdentifier,
    state: state || collectState(),
  });

  // Push event to server
  gMessageQueue.send(event_json);

  // Add event to local storage list
  let all_events = window.localStorage.getItem("all_events") || "[]";
  all_events = JSON.parse(all_events);
  all_events.push(event_json);
  window.localStorage.setItem("all_events", JSON.stringify(all_events));
}
export async function initializeVeelooka() {
  const endTime = await connectToServer();

  document.addEventListener("mousedown", (event) => {
    let elementIdentifier = getElementVeelookaIdentifiersAsString(event.target);
    reportEvent("mousedown", elementIdentifier);
  });

  document.addEventListener("click", (event) => {
    let elementIdentifier = getElementVeelookaIdentifiersAsString(event.target);
    reportEvent("click", elementIdentifier);
  });

  document.addEventListener("keydown", function (event) {
    let elementIdentifier = getElementVeelookaIdentifiersAsString(event.target);
    reportEvent("keydown", elementIdentifier);
  });

  document.addEventListener("keyup", function (event) {
    let elementIdentifier = getElementVeelookaIdentifiersAsString(event.target);
    reportEvent("keyup", elementIdentifier);
  });

  document.reportVeelookaEvent = reportEvent;

  return endTime;
}

function betweenIncluding(min, val, max) {
  return val >= min && val <= max;
}

async function connectToServer() {
  var args = new URLSearchParams(window.location.search);
  var session = args.get("s");
  var sessionEndPoint = args.get("endpoint");
  if (!sessionEndPoint) {
    var pageEndpoint = window.location.origin + window.location.pathname;
    sessionEndPoint = pageEndpoint + "..";
  }

  try {
    sessionEndPoint += "/test/sessions/" + session;
    const startSessionEndPoint = sessionEndPoint + "/start/";
    const startResponse = await fetch(startSessionEndPoint, {
      method: "POST",
    });
    if (!betweenIncluding(200, startResponse.status, 299)) {
      throw createErrorMessage("Cannot currently test", startResponse.status);
    } else {
      var websocketEndpoint =
        sessionEndPoint.replace("http", "ws") + "/stream/";
      gMessageQueue = new MessageQueue(websocketEndpoint);
      gMessageQueue.connect();
    }
    return await startResponse.json();
  } catch (error) {
    displayErrorMessage(error.message);
    throw error;
  }

  function createErrorMessage(message, statusCode) {
    var errorCodes = {
      404: "No such session",
      410: "Test time has expired",
    };
    var shortErrorMessage = errorCodes[statusCode] || statusCode;
    return new Error(message + ": " + shortErrorMessage);
  }

  function displayErrorMessage(message) {
    const overlay = document.createElement("div");
    overlay.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    `;

    const messageDiv = document.createElement("div");
    messageDiv.style.cssText = `
        background-color: white;
        color: #333;
        padding: 20px;
        border-radius: 5px;
        max-width: 80%;
        text-align: center;
        font-family: Arial, sans-serif;
        font-size: 18px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    `;
    messageDiv.textContent = message;

    messageDiv.appendChild(document.createElement("br"));
    overlay.appendChild(messageDiv);

    document.body.appendChild(overlay);
  }
}

export const finishAndSubmitVeelookaTest = () => {
  var args = new URLSearchParams(window.location.search);
  var session = args.get("s");
  var sessionEndPoint = args.get("endpoint");
  if (!sessionEndPoint) {
    var pageEndpoint = window.location.origin + window.location.pathname;
    sessionEndPoint = pageEndpoint + "..";
  }
  sessionEndPoint += "/test/sessions/" + session;
  (async function () {
    // Upload backup event log
    gMessageQueue.send(window.localStorage.getItem("all_events"));
    window.localStorage.removeItem("all_events");
    await new Promise((resolve) => setTimeout(resolve, 3000)).then(() => {
      fetch(sessionEndPoint + "/finish/", { method: "POST" }).then(() => {
        window.location = "thankyou.html";
      });
    });
  })();
};

function getElementVeelookaIdentifiersAsString(element) {
  return getElementVeelookaIdentifiers(element).reverse().join("/");
}

function getElementVeelookaIdentifiers(element) {
  let elementIdentifiers = [];
  let currentElement = element;

  //traverse all parents and build chain of tags:
  while (currentElement) {
    let veelookaType = currentElement.getAttribute("veelooka");
    if (veelookaType) {
      elementIdentifiers.push(veelookaType);
    }
    currentElement = currentElement.parentElement;
  }
  if (elementIdentifiers.length < 1) {
    if (element) {
      // no veelooka metadata found, identify element as best as possible
      let defaultElementIdentifier = element.id || element.value;
      elementIdentifiers = [defaultElementIdentifier];
    }
  }
  return elementIdentifiers;
}

function getSessionMetadata(metaDataEndpoint) {
  // TODO: verify this is actually needed. an example for the response:
  //
  //Response {type: 'cors', url: 'https://veelooka.com/test/sessions/s2f3928795df94e9a919f18cc67814176/metadata/', redirected: false, status: 200, ok: true, …}body: (...)bodyUsed: falseheaders: Headers {}ok: trueredirected: falsestatus: 200statusText: "OK"type: "cors"url: "https://veelooka.com/test/sessions/s2f3928795df94e9a919f18cc67814176/metadata/"[[Prototype]]: Response
  // in the future implement this with fetching from DB, instead of a redundant endpoint
  return fetch(metaDataEndpoint);
}
