import React from "react";
import { Box, Typography } from "@mui/material";

const NoInternetDialog = () => {
  return (
    <Box
      sx={{
        backgroundColor: "red",
        padding: "15px 30px",
        borderRadius: "8px",
        position: "fixed",
        bottom: 16,
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 9999,
      }}
    >
      <Typography
        variant="body1"
        sx={{
          color: "white",
          fontSize: "14px",
          textAlign: "center",
        }}
      >
        No Internet Connection
      </Typography>
    </Box>
  );
};

export default NoInternetDialog;
