import { useEffect, useState } from "react";
import Store from "../store";
import Cart from "../cart";
import { clearLocalStorageValue } from "../utils";
import BugListPopup from "../bugListPopup";
import BugReportPopup from "../bugReportPopUp";
import { OurContext } from "../../context/context";
import {
  finishAndSubmitVeelookaTest,
  initializeVeelooka,
} from "../veelookaEngine";
import { BugGenerator } from "../bugGenerator";
import Timer from "../Timer";
import { Box, Grid, Paper, Divider, Button, ButtonGroup } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import LogoAppBar from "../../LogoAppBar.png";
import LogoTimer from "../../LogoTimer.png";
import Guidelines from "../guidelinesModal/guidelinesModal";
import SpecSheet from "../specSheet/SpecSheet";
import Drawer from "@mui/material/Drawer";
import LoadingDialog from "../loadingDialog";
import NoInternetDialog from "../NoInternetDialog";

let START_RECORDING = "Record A Bug";
let END_RECORDING = "End Recording";

function TestSession() {
  let catalogItems = [
    { name: "Rock", weight: 8, price: 4 },
    { name: "Paper", weight: 1, price: 1 },
    { name: "Scissors", weight: 2, price: 8 },
  ];
  const data = {
    bugGenerator: BugGenerator(),
    itemsData: catalogItems.map((data) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      let state = useState(0);
      data.state = state;
      return data;
    }),
  };

  const [isSpecSheetOpen, setIsSpecSheetOpen] = useState(false);
  const [isGuidelinesOpen, setIsGuidelinesOpen] = useState(false);
  const [remainingTime, setRemainingTime] = useState(3600);
  const [sessionId, setSessionId] = useState(null);
  const [wasStarted, setWasStarted] = useState(false);
  const [isDisconnectionDialog, setIsDisconnectionDialog] = useState(false);
  const [isConnectingDialog, setIsConnectingDialog] = useState(false);
  const [disconnectionTimer, setDisconnectionTimer] = useState(null);

  let loadedCaptionFromLastRefresh =
    localStorage.getItem("recordButtonCaption") || START_RECORDING;
  let [recordButtonCaption, setRecordButtonCaption] = useState(
    loadedCaptionFromLastRefresh
  );
  let [recordingActivated, setRecordingActivated] = useState(
    "" + (loadedCaptionFromLastRefresh == END_RECORDING)
  );
  let [isBugReportDisabled, setIsBugReportDisabled] = useState(true);
  let [bugListPopUp, setBugListPopUp] = useState("");
  let [resetWindowPopUp, setresetWindowPopUp] = useState("");
  let [isDesktop, setDesktop] = useState(window.innerWidth > 1024);
  let [lockAllButtons, setLockAllButtons] = useState(
    recordButtonCaption === END_RECORDING || false
  );
  const [continueButtonVisible, setContinueButtonVisible] = useState(true);

  document.reportConnectionState = function (isConnected) {
    if (isConnected) {
      setIsDisconnectionDialog(false);
      setIsConnectingDialog(false);
      if (disconnectionTimer) {
        clearTimeout(disconnectionTimer);
        setDisconnectionTimer(null);
      }
    } else {
      setIsConnectingDialog(true);
      const timer = setTimeout(() => {
        setIsDisconnectionDialog(true);
        setIsConnectingDialog(false);
      }, 5000);
      setDisconnectionTimer(timer);
    }
  };

  const handleConnectionChange = () => {
    document.reportConnectionState(navigator.onLine);
  };

  function resetEntirePage(startRecording) {
    if (startRecording) {
      window.localStorage.setItem("recordButtonCaption", END_RECORDING);
      window.localStorage.setItem("disableButtonsOnRecord", "true");
    } else {
      window.localStorage.removeItem("recordButtonCaption");
      window.localStorage.removeItem("disableButtonsOnRecord");
    }
    setresetWindowPopUp(
      <LoadingDialog open={true} message={"Resetting the app..."} />
    );
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  }

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    async function fetchEndTime() {
      const endTime = await initializeVeelooka();
      return endTime["endTime"];
    }

    fetchEndTime().then((endTime) => {
      setRemainingTime(calculateTimeRemaining(endTime));
    });
    clearLocalStorageValue("recordButtonCaption");

    function openModalsForFirstTime() {
      var args = new URLSearchParams(window.location.search);
      var session = args.get("s");
      setSessionId(session);
      triggerTempModals(session);
    }
    openModalsForFirstTime();

    window.addEventListener("resize", updateMedia);
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("resize", updateMedia);
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, []);

  function calculateTimeRemaining(endTime) {
    const currTime = Date.now();
    return endTime - parseInt(currTime / 1000);
  }

  function triggerTempModals(session) {
    const wasSpecSheetOpen = localStorage.getItem(
      "wasSpecSheetOpen-" + session
    );
    const wasGuidelinesOpen = localStorage.getItem(
      "wasGuidelinesOpen-" + session
    );
    if (!wasSpecSheetOpen) {
      setIsSpecSheetOpen(true);
    }
    if (!wasGuidelinesOpen) {
      setIsGuidelinesOpen(true);
    }
  }

  function onRecordButtonClicked() {
    if (recordButtonCaption == END_RECORDING) {
      setRecordingActivated("false");
      setRecordButtonCaption("Record A Bug");
      setIsBugReportDisabled(false);
      setLockAllButtons(true);
      window.localStorage.setItem("disableButtonsOnRecord", "true");
    } else {
      localStorage.setItem("recordButtonCaption", "true");
      resetEntirePage(true);
    }
  }

  function closeBugListPopUp() {
    setBugListPopUp("");
  }

  function onRecordViewReportedBugsButtonClicked() {
    setBugListPopUp(<BugListPopup onClose={closeBugListPopUp} />);
  }

  function finishAndSubmitTest() {
    const response = window.confirm("Are you sure you want to end the test?");
    if (response) {
      finishAndSubmitVeelookaTest();
    }
  }

  const handleSpecSheetOpen = () => {
    setIsSpecSheetOpen(true);
  };

  const handleSpecSheetClose = () => {
    localStorage.setItem("wasSpecSheetOpen-" + sessionId, "true");
    setContinueButtonVisible(false);
    setIsSpecSheetOpen(false);
    setWasStarted(true);
  };

  const handleGuidelinesOpen = () => {
    setIsGuidelinesOpen(true);
  };

  const handleGuidelinesClose = () => {
    localStorage.setItem("wasGuidelinesOpen-" + sessionId, "true");
    setIsGuidelinesOpen(false);
  };

  catalogItems.forEach((itemName) => data.itemsData.p);

  return isDesktop ? (
    <OurContext.Provider value={data}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundImage: "linear-gradient(to right, #49a2da, #e97ca1)",
        }}
      >
        <Toolbar
          variant="dense"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ flexGrow: 1 }} />
          <img src={LogoAppBar} alt="LogoAppBar" />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent">
        <Toolbar />
        <ButtonGroup
          fullWidth
          sx={{ justifyContent: "space-between" }}
        ></ButtonGroup>

        <Box
          sx={{
            display: "flex",
            maxWidth: "200px",
            flexDirection: "column",
            alignItems: "center",
            p: 4,
            background: "white",
          }}
        >
          <Timer remainingTime={remainingTime} />

          <ButtonGroup
            orientation="vertical"
            aria-label="vertical outlined button group"
            style={{ marginTop: "30px" }}
          >
            <Button
              variant="contained"
              sx={{ marginBottom: "10px", background: "#f07a9e" }}
              onClick={onRecordButtonClicked}
              veelooka="veelookaRecordButton"
              veelookastate={recordingActivated}
              disabled={lockAllButtons && recordButtonCaption !== END_RECORDING}
            >
              {recordButtonCaption}
            </Button>
            <Button
              variant="contained"
              color="info"
              style={{ background: "#42a4dc" }}
              sx={{ marginBottom: "10px" }}
              veelooka="veelookaView Reported Bugs"
              onClick={onRecordViewReportedBugsButtonClicked}
              disabled={lockAllButtons}
            >
              View Reported Bugs
            </Button>
            <Button
              variant="contained"
              color="info"
              style={{ background: "#42a4dc" }}
              sx={{ marginBottom: "10px" }}
              veelooka="veelookaResetApp"
              onClick={() => {
                resetEntirePage(false);
              }}
              disabled={lockAllButtons}
            >
              Reset App State
            </Button>
            <Button
              variant="contained"
              color="info"
              style={{ background: "#42a4dc" }}
              sx={{ marginBottom: "10px" }}
              veelooka="specSheetOpen"
              onClick={handleSpecSheetOpen}
              disabled={lockAllButtons}
            >
              APP SPECIFICATION
            </Button>
            <Button
              variant="contained"
              color="info"
              style={{ background: "#42a4dc" }}
              sx={{ marginBottom: "15px" }}
              veelooka="guidelinesOpen"
              onClick={handleGuidelinesOpen}
              disabled={lockAllButtons}
            >
              Guidelines
            </Button>
            <Button
              variant="contained"
              style={{ marginTop: "20px" }}
              color="error"
              veelooka="veelookaSubmitTestButton"
              onClick={finishAndSubmitTest}
              disabled={lockAllButtons}
            >
              Finish & Submit Test
            </Button>
          </ButtonGroup>
          {bugListPopUp}
          {resetWindowPopUp}
          {isConnectingDialog && (
            <LoadingDialog open={true} message={"Connecting to server..."} />
          )}
          {isDisconnectionDialog && <NoInternetDialog />}
          <div style={{ bottom: "20px", position: "absolute" }}>
            <img src={LogoTimer} alt="LogoAppBar" />
          </div>
        </Box>
      </Drawer>

      <SpecSheet
        isOpen={isSpecSheetOpen}
        handleClose={handleSpecSheetClose}
        wasStarted={wasStarted}
      />
      <Guidelines
        isOpen={isGuidelinesOpen}
        handleClose={handleGuidelinesClose}
        wasStarted={wasStarted}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginLeft: "150px",
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: "80px",
          }}
        >
          <Grid item xs={8} sm={8}>
            <Paper
              elevation={3}
              style={{
                padding: "20px",
                borderRadius: "15px",
              }}
            >
              {/* Include Store and Cart components in a Grid */}
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Store
                    itemsData={data.itemsData}
                    disabled={!isBugReportDisabled}
                  />
                </Grid>
                <Divider orientation="vertical" flexItem />

                <Grid item xs={5}>
                  <Cart disabled={!isBugReportDisabled} />
                </Grid>
              </Grid>
            </Paper>
            <Box sx={{ marginTop: "30px" }}>
              <BugReportPopup closeBugReportPopUp={isBugReportDisabled} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </OurContext.Provider>
  ) : (
    <div>
      <h1>This screen resolution is not supported</h1>
    </div>
  );
}

export default TestSession;
