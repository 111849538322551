import React, { Component } from "react";
import StoreItem from "./storeItem";

//those are the states being passed from the root component
const Store = ({ itemsData, disabled }) => {
  return (
    <div veelooka="Store">
      <h2 style={{ marginBottom: "30px" }}>Store</h2>
      {itemsData.map((item, key) => {
        const [state, setState] = item.state;
        return (
          <StoreItem
            key={key}
            StoreItemName={item.name}
            OnChangeStoreItemData={setState}
            disabled={disabled}
          ></StoreItem>
        );
      })}
    </div>
  );
};

export default Store;
