import React, { useState, useEffect } from "react";
//https://github.com/adazzle/react-data-grid/blob/main/README.md
import ReactDataGrid, { textEditor } from "react-data-grid";
import "react-data-grid/lib/styles.css";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { BUG_CATEGORIES, PRIORITIES } from "./constants";
import "react-data-grid/lib/styles.css";
import {
  readBugsDataForSession,
  setBugForSession,
  removeBugForSession,
} from "./utils";

/**
 * A popup window which blocks out the entire screen
 */
const BugListPopUp = ({ onClose: onClose }) => {
  const [rows, setRows] = useState(readBugsDataForSession());

  function onRowsDataChanged(newRows, { indexes: indexes, column: column }) {
    indexes.forEach((i) => {
      setBugForSession(newRows[i]);
    });
    setRows(newRows);
  }

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.keyCode === 27) {
        onClose();
      }
    };

    window.addEventListener("keydown", handleEscapeKey);

    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, [onClose]);

  function deleteRow(row) {
    removeBugForSession(row);
    setRows(readBugsDataForSession);
  }

  function closeWhenClicked(event) {
    if (event.target === event.currentTarget) {
      onClose();
    }
  }

  const columns = [
    {
      key: "category",
      width: "20%",
      name: "Category",
      editor: createDropDownEditor("category", BUG_CATEGORIES[1]),
    },
    {
      key: "priority",
      width: "15%",
      name: "Priority",
      editor: createDropDownEditor("priority", PRIORITIES[1]),
    },
    {
      key: "description",
      width: "65%",
      name: "Description",
      editor: textEditor,
    },
  ];

  return (
    <div
      veelooka="bugListPopup"
      onClick={closeWhenClicked}
      style={{
        zIndex: 1000,
        padding: "16px",
        position: "fixed",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        background: "rgba(0,0,0,0.8)",
      }}
    >
      <div
        onClick={closeWhenClicked}
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          padding: 16,
        }}
      >
        <div
          className="centered"
          style={{ background: "white", top: 64, padding: 16, width: "90%" }}
        >
          <IconButton
            style={{ position: "absolute", right: 5, top: 5 }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <h1>Bugs List</h1>

          <ReactDataGrid
            columns={columns}
            rows={rows}
            rowHeight={55}
            onRowsChange={onRowsDataChanged}
            onCellClick={(args, event) => {
              if (
                args.column.key === "category" ||
                args.column.key === "priority"
              ) {
                event.preventGridDefault();
                args.selectCell(true);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

function createDropDownEditor(columnName, values) {
  return function DropDownEditor({ row, onRowChange }) {
    return (
      <select
        value={row[columnName]}
        onChange={(event) => {
          let changedRow = { ...row };
          changedRow[columnName] = event.target.value;
          onRowChange(changedRow, true);
        }}
        autoFocus
      >
        {values.map((v) => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </select>
    );
  };
}

export default BugListPopUp;
